import React from "react";
import axios from "axios";
import { GlobalConfig } from "../config";
import {
    useSessionDataContext,
    useSetSessionDataForKeyContext,
} from "../contexts/sessioncontext";
import { useDraggable } from "./draggable";
import ControlPanelInterlaced from "./controlpanel_interlaced";
import ControlPanelDither from "./controlpanel_dither";

export default function ControlPanel() {
    const sessionData = useSessionDataContext();
    const setSessionDataForKey = useSetSessionDataForKeyContext();

    // make the window draggable.
    const disabled = false;
    const { ref, styles } = useDraggable({ disabled });
    const [renderStyle, setRenderStyle] = React.useState("interlaced");

    function clickFileChooser(event) {
        document.getElementById("fileChooser").click();
    }

    function handleStyleChange(event) {
        setSessionDataForKey("imageFilenameDst", "");
        setRenderStyle(event.target.value);
    }

    function handleFitChange(event) {
        setSessionDataForKey("imageFilenameDst", "");
        setSessionDataForKey("image_fit", event.target.value);
    }

    function handleReset(event) {
        setSessionDataForKey("imageFilenameDst", "");
    }

    function handleToggleMinimize(event) {
        let is_minimized = !sessionData.is_minimized;
        setSessionDataForKey("is_minimized", is_minimized);
        console.log("af is_minimized: " + is_minimized);
    }

    function handleFileChooser(event) {
        console.log(event.target.files[0]);
        let data = new FormData();
        data.append("image", event.target.files[0]);
        data.append("session_id", sessionData.sessionID);
        const options = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
            },
        };
        const url = GlobalConfig.API_URL + "/api/upload_image";
        console.log("Requesting: " + url);
        axios
            .post(url, data, options)
            .then((res) => {
                console.log(res);
                if (res.status == 200 && res.data.success == true) {
                    setSessionDataForKey(
                        "imageFilenameSrc",
                        res.data.data.image_filename_src,
                    );
                    setSessionDataForKey("imageFilenameDst", "");
                    // setSessionDataForKey("dialog_msg", res.data.data.msg);
                    // setSessionDataForKey("dialog_button", true);
                    console.log(res.data.data.msg);
                }
            })
            .catch((error) => {
                console.log(error.message);
                if (error.message == "Network error") {
                    setSessionDataForKey("dialog_msg", error.message + " Maximum image size: 8Mb.");
                }
                else {
                    setSessionDataForKey("dialog_msg", error.message);
                }
                setSessionDataForKey("dialog_button", true);
                setSessionDataForKey("imageFilenameSrc", "");
                setSessionDataForKey("imageFilenameDst", "");
            });
    }

    function handleDownload(event) {
        console.log("button download pressed...");
        setSessionDataForKey("dialog_msg", "Preparing for download...");
        setSessionDataForKey("dialog_button", false);
        let data = new FormData();
        data.append("session_id", sessionData.sessionID);
        data.append("image_filename_src", sessionData.imageFilenameSrc);
        data.append("image_filename_dst", sessionData.imageFilenameDst);
        const options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: "blob",
        };
        axios
            .post(GlobalConfig.API_URL + "/api/download", data, options)
            .then((res) => {
                console.log(res);
                let filename =
                    sessionData.imageFilenameDst.substring(
                        0,
                        sessionData.imageFilenameDst.length - 4,
                    ) + ".zip";
                // @Rodrigo: What to do here?
                if (res.status == 200) {
                    setSessionDataForKey("dialog_msg", "");
                    setSessionDataForKey("dialog_button", true);
                    // create file link in browser's memory
                    const href = URL.createObjectURL(res.data);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else {
                    setSessionDataForKey("dialog_msg", res.data.data.error);
                    setSessionDataForKey("dialog_button", true);
                }
            })
            .catch((error) => {
                console.log(error.message);
                setSessionDataForKey("dialog_msg", error.message);
                setSessionDataForKey("dialog_button", true);
            });
    }

    return (
        <div className="panel control-panel" style={styles}>
            {/* Panel handlebar with minimize button */}
            <div className="panel handle" ref={ref}>
                <button onClick={handleToggleMinimize}>-</button>
            </div>
            {sessionData.is_minimized ? (
                <></>
            ) : (
                <div>
                    {/* Image import button */}
                    <div className="control-panel-inner">
                        <div className="button-grid">
                            <div className="button-grid-item">
                                <button
                                    id="goto_digitizer"
                                    type="button"
                                    onClick={() =>
                                        (window.location.href =
                                            "https://msx-digitizer-gallery.blitzblit.com")
                                    }
                                >
                                    Go to gallery
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="button-grid">
                            <div className="button-grid-item">
                                <input
                                    type="file"
                                    id="fileChooser"
                                    name="fileChooser"
                                    onChange={handleFileChooser}
                                />
                                <button onClick={clickFileChooser}>
                                    Import image...{" "}
                                </button>
                            </div>
                        </div>
                        {/* Image style selector */}
                        {sessionData.imageFilenameSrc == "" ? (
                            <></>
                        ) : (
                            <div>
                                <div className="label-input-grid">
                                    <div className="label-input-grid-item">
                                        <label htmlFor="fit">Image fit</label>
                                    </div>
                                    <div className="label-input-grid-item">
                                        <select
                                            id="fit"
                                            name="fit"
                                            value={sessionData.image_fit}
                                            onChange={handleFitChange}
                                        >
                                            <option value="contain">
                                                Fit in
                                            </option>
                                            <option value="cover">
                                                Fill in
                                            </option>
                                        </select>
                                    </div>

                                    <div className="label-input-grid-item">
                                        <label htmlFor="style">Style</label>
                                    </div>
                                    <div className="label-input-grid-item">
                                        <select
                                            id="style"
                                            name="style"
                                            value={renderStyle}
                                            onChange={handleStyleChange}
                                        >
                                            <option value="interlaced">
                                                Color interlaced
                                            </option>
                                            <option value="dither">
                                                Dither
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                {renderStyle == "interlaced" && (
                                    <ControlPanelInterlaced />
                                )}
                                {renderStyle == "dither" && (
                                    <ControlPanelDither />
                                )}
                                <div className="button-grid">
                                    <div className="button-grid-item">
                                        <button
                                            onClick={handleDownload}
                                            disabled={
                                                sessionData.imageFilenameDst ==
                                                ""
                                            }
                                        >
                                            Download
                                        </button>
                                    </div>
                                    <div className="button-grid-item">
                                        <button
                                            onClick={handleReset}
                                            disabled={
                                                sessionData.imageFilenameDst ==
                                                ""
                                            }
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>{" "}
                </div>
            )}
        </div>
    );
}
