import React from "react";
import {
    useSessionDataContext,
    useSetSessionDataForKeyContext,
} from "../contexts/sessioncontext";

export default function messageDialog() {
    const sessionData = useSessionDataContext();
    const setSessionDataForKey = useSetSessionDataForKeyContext();

    function handleClick(event) {
        setSessionDataForKey("dialog_msg", "");
        setSessionDataForKey("dialog_button", true);
    }
    if (sessionData.dialog_msg != "") {
        return (
            <div className="message-dialog-overlay">
                <div className="panel message-dialog">
                    <div className="handle"></div>
                    <div className="message-dialog-inner">
                        <p> {sessionData.dialog_msg} </p>
                        {
                            sessionData.dialog_button ? 
                            (<div className="align-right"><button type="button" onClick={handleClick}> Ok </button></div>) : (<></>) 
                        } 
                    </div>
                </div>
            </div>
        );
    } else {
        return;
    }
}
