import React from "react";
import ControlPanel from "./components/controlpanel";
import ImageViewer from "./components/imageviewer";
import MessageDialog from "./components/messagedialog";
import { SessionContextProvider } from "./contexts/sessioncontext";

export default function App() {
    return (
        <div>
            <React.StrictMode>
                <SessionContextProvider>
                    <MessageDialog />
                    <ControlPanel />
                    <ImageViewer />
                </SessionContextProvider>
            </React.StrictMode>
        </div>
    );
}
