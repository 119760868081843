import { useCallback, useEffect, useRef, useState } from "react";

export function useDraggable(options) {
    const [node, setNode] = useState(null);
    const ref = useCallback((newNode) => {
        setNode(newNode);
    }, []);

    // We use refs for everything that does need to trigger rerenders on change
    const isDraggingRef = useRef(false);
    // This ref is kept to keep track of the last delta, so that when the node is
    // dragged again we can take it into account when calculating the new position.
    const lastDeltaRef = useRef({ x: 0, y: 0 });
    const startPositionRef = useRef({ x: 0, y: 0 });

    // perfect use case for https://reactjs.org/docs/hooks-reference.html#usedeferredvalue
    const [delta, setDelta] = useState({ x: 0, y: 0 });

    const onStart = (e) => {
        e.preventDefault();
        startPositionRef.current = { x: e.clientX, y: e.clientY };
        isDraggingRef.current = true;
    };

    const onMove = (e) => {
        if (!isDraggingRef.current) {
            return;
        }
        e.preventDefault();

        setDelta({
            x: e.clientX - startPositionRef.current.x + lastDeltaRef.current.x,
            y: e.clientY - startPositionRef.current.y + lastDeltaRef.current.y,
        });
    };

    const onEnd = (e) => {
        if (isDraggingRef.current) {
            e.preventDefault();
            lastDeltaRef.current = {
                x:
                    e.clientX -
                    startPositionRef.current.x +
                    lastDeltaRef.current.x,
                y:
                    e.clientY -
                    startPositionRef.current.y +
                    lastDeltaRef.current.y,
            };
        }
        isDraggingRef.current = false;
    };

    useEffect(() => {
        if (!options?.disabled) {
            node?.addEventListener("pointerdown", onStart);
            // We use document here in order to capture the pointer events outside of
            // the dragged element.
            document?.addEventListener("pointermove", onMove);
            document?.addEventListener("pointerup", onEnd);
        }

        return () => {
            node?.removeEventListener("pointerdown", onStart);
            document?.removeEventListener("pointermove", onMove);
            document?.removeEventListener("pointerup", onEnd);
        };
    }, [node, options?.disabled]);

    return {
        ref,
        delta,
        styles: { transform: `translate(${delta.x}px, ${delta.y}px)` },
    };
}
