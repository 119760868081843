import React from "react";
import axios from "axios";
import { GlobalConfig } from "../config";
import {
    useSessionDataContext,
    useSetSessionDataForKeyContext,
} from "../contexts/sessioncontext";

export default function ControlPanelInterlaced() {
    const sessionData = useSessionDataContext();
    const setSessionDataForKey = useSetSessionDataForKeyContext();

    const [formData, setFormData] = React.useState({
        style: "interlaced",
        colorspace: "lab",
        brightness: "0",
        contrast: "50",
        interlace_delta: "50",
        interlace_delta_offset: "0",
        k: "16",
        session_id: sessionData.sessionID,
        image_fit: sessionData.image_fit,
    });

    function handleChange(event) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            session_id: sessionData.sessionID,
            image_fit: sessionData.image_fit,
            [event.target.name]: event.target.value,
        }));
    }

    console.log(formData);

    function handleDigitize(event) {
        console.log("button digitize pressed...");
        setSessionDataForKey("dialog_msg", "Computing, please wait...");
        setSessionDataForKey("dialog_button", false);
        let data = new FormData();
        data.append("session_id", sessionData.sessionID);
        data.append("image_fit", sessionData.image_fit);
        data.append("image_filename_src", sessionData.imageFilenameSrc);
        for (const [key, value] of Object.entries(formData)) {
            data.append(key, value);
        }
        const options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        };
        axios
            .post(GlobalConfig.API_URL + "/api/to_screen2", data, options)
            .then((res) => {
                if (res.status == 200 && res.data.success == true) {
                    setSessionDataForKey("dialog_msg", "");
                    setSessionDataForKey("dialog_button", true);
                    setSessionDataForKey(
                        "imageFilenameDst",
                        res.data.data.image_filename_dst,
                    );
                } else if (res.status == 200 && res.data.success == false) {
                    setSessionDataForKey("dialog_msg", res.data.data.msg);
                    setSessionDataForKey("dialog_button", true);
                    setSessionDataForKey("imageFilenameDst", "");
                }
            })
            .catch((error) => {
                setSessionDataForKey("dialog_msg", error.message);
                setSessionDataForKey("dialog_button", true);
                setSessionDataForKey("imageFilenameDst", "");
                console.log(error.message);
            });
    }

    return (
        <div>
            <div className="label-input-grid">
                <div className="label-input-grid-item">
                    <label htmlFor="colorspace">Conversion</label>
                </div>
                <div className="label-input-grid-item">
                    <select
                        id="colorspace"
                        name="colorspace"
                        value={formData.colorspace}
                        onChange={handleChange}
                    >
                        <option value="lab">CIELab</option>
                        <option value="ycrcb">YCrCb</option>
                        <option value="rgb">RGB</option>
                    </select>
                </div>

                <div className="label-input-grid-item">
                    <label htmlFor="brightness">Brightness</label>
                </div>
                <div className="label-input-grid-item">
                    <input
                        type="range"
                        name="brightness"
                        id="brightness"
                        min="-50"
                        max="50"
                        step="1"
                        value={formData.brightness}
                        onChange={handleChange}
                    />
                </div>

                <div className="label-input-grid-item">
                    <label htmlFor="contrast">Contrast</label>
                </div>
                <div className="label-input-grid-item">
                    <input
                        type="range"
                        name="contrast"
                        id="contrast"
                        min="0"
                        max="100"
                        step="1"
                        value={formData.contrast}
                        onChange={handleChange}
                    />
                </div>

                <div className="label-input-grid-item">
                    <label htmlFor="interlace_delta">Interlace</label>
                </div>
                <div className="label-input-grid-item">
                    <input
                        type="range"
                        name="interlace_delta"
                        id="interlace_delta"
                        min="0"
                        max="100"
                        step="1"
                        value={formData.interlace_delta}
                        onChange={handleChange}
                    />
                </div>

                <div className="label-input-grid-item">
                    <label htmlFor="k">Complexity</label>
                </div>
                <div className="label-input-grid-item">
                    <input
                        type="range"
                        name="k"
                        id="k"
                        min="2"
                        max="16"
                        step="1"
                        value={formData.k}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="button-grid">
                <div className="button-grid-item">
                    <button onClick={handleDigitize}>Digitize</button>
                </div>
            </div>
        </div>
    );
}
