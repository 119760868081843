import React from "react";
import {GlobalConfig} from "../config";
import splash_img from "../../assets/images/splash.png";
import {
    useSessionDataContext,
    useSetSessionDataForKeyContext,
} from "../contexts/sessioncontext";

export default function ImageViewer(props) {
    console.log("GlobalConfig");
    console.log(GlobalConfig);
    const sessionData = useSessionDataContext();
    let image_url = "";

    console.log(sessionData);
    if (sessionData.imageFilenameDst !== "") {
        image_url = GlobalConfig.API_URL + "/" + GlobalConfig.SESSIONS_FOLDER + "/" + sessionData.sessionID + "/" + sessionData.imageFilenameDst;
        console.log("Showing DST");
    } else if (sessionData.imageFilenameSrc !== "") {
        image_url = GlobalConfig.API_URL + "/" + GlobalConfig.SESSIONS_FOLDER + "/" + sessionData.sessionID + "/" + sessionData.imageFilenameSrc;
        console.log("Showing SRC");
    } else {
        image_url = "";
    }

    return (
        <div className="imageviewer-border">
            <div className="imageviewer-canvas">
                {image_url !== "" ? (
                    <img
                        src={image_url}
                        style={{ objectFit: sessionData.image_fit }}
                    />
                ) : (
                    <img src={splash_img} alt="MSX image" style={{ objectFit: "contain" }} />
                )}
            </div>
        </div>
    );
}
